import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ReactHtmlParser from "react-html-parser"

import { siteMetaCtx } from "../store/siteMeta/context"
import { RoomFullPage } from "../components/RoomPage/RoomFullPage"
import { Breadcrumb } from "../ui/Breadcumb"
import { Section } from "../ui/Section"

const ContactUsTemplate = ({ pageContext, uri, ...props }) => {
  const { basic_info, full_page, content, ...restData } = pageContext.acf

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <RoomFullPage data={full_page} />
        <section className="position-relative">
          <div className="contact-us-body">
            <Breadcrumb
              paths={basic_info.breadcumb.map(path => ({
                text: path.name,
                link_url: path.link_name,
              }))}
            />
            {content.sections.map(section => (
              <div className="wrapper">
                <Section title={section.title} subtitle={section.subtitle}>
                  <div className="row my-4">
                    <div className="col-auto map-wrapper">
                      {ReactHtmlParser(section.content_large)}
                    </div>
                    <div className="col-auto content-wrapper">
                      {ReactHtmlParser(section.content_small)}
                    </div>
                  </div>
                </Section>
              </div>
            ))}
          </div>
        </section>
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export default ContactUsTemplate
